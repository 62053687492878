@import "../../assets/styles/utils";

header{
  .header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    left: 0;
    z-index: 1000;
    width: 100%;
    transition: all .4s;
    @include res(top,0);
    @include res(height,$header-height-base,$header-height-ratio);
    &:hover,&.show-menu,&.scroll{
      &::after{
        opacity: 0;
      }
      .white-bg{
        opacity: 1;
      }
      .logo{
        img{
          &.logo-white{
            opacity: 0;
          }
          &.logo-color{
            opacity: 1;
          }
        }
      }
      .right{
        .nav{
          ul li .title{
            color: $color-main-dark;
            a::after{
              @include res(bottom,30%);
            }
          }
        }
        .right-fun{
          .language{color: $color-main-dark;}
          .hamburger>div, .hamburger>div:after, .hamburger>div:before, .hamburger>view, .hamburger>view:after, .hamburger>view:before{
            background-color: $color-main-dark;
          }
        }
      }
    }
    &.scroll{
      @include res(height,1.1rem,(md:1.2rem,sm:1.6rem,xs:1.1rem));
    }
    &.trans{
      transform: translateY(-100%);
    }
    .white-bg{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255,255,255,.9);
      z-index: -1;
      opacity: 0;
      transition: all .5s;
      box-shadow: 0 0 .1rem rgba(0,0,0,.1);
      border-bottom-left-radius: .5rem;
      border-bottom-right-radius: .5rem;
      backdrop-filter: blur(10px);
    }
    .logo{
      position: relative;
      img{
        display: block;
        height: auto;
        transition: all .3s;
        @include res(width,1.75rem,(md:2.2rem,sm:2.8rem,xs:2rem));
        &.logo-color{
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0;
        }
      }
    }
    .right{
      align-items: center;
      height: 100%;
      @include res(margin-top,.1rem,(md:0));
      @include res(display,flex);
      .nav{
        height: 100%;
        @include res(display,block,(md:none));
        ul{
          align-items: center;
          height: 100%;
          @include res(display,flex);
          li{
            height: 100%;
            position: relative;
            @include res(padding-left,.25rem);
            @include res(padding-right,.25rem);
            .title{
              height: 100%;
              color: #fff;
              transition: color .3s;
              @include res(font-size,.2rem,(md:.3rem,sm:.4rem,xs:.32rem));
              a{
                height: 100%;
                display: flex;
                align-items: center;
                position: relative;
                &::after{
                  content: '';
                  display: block;
                  width: 100%;
                  background-color: $color-yellow;
                  position: absolute;
                  left: 0;
                  transform-origin: center;
                  transform: scaleX(0);
                  transition: all .3s;
                  @include res(bottom,35%);
                  @include res(height,.02rem);
                }
              }
            }
            .sub-nav{
              position: absolute;
              color: $color-main-dark;
              align-items: flex-start;
              justify-content: flex-start;
              transition: top .5s, opacity .3s, visibility .3s;
              opacity: 0;
              visibility: hidden;
              transition-delay: 0s;
              white-space: nowrap;
              z-index: 2;
              @include res(display,flex);
              @include res(top,70%);
              @include res(left,.25rem);
              @include res(padding-bottom,.4rem);
              >div{
                &:not(:last-child){
                  @include res(margin-right,.6rem);
                }
                a{
                  display: block;
                  transition: all .3s;
                  @include res(margin-bottom,.15rem);
                  &:hover{
                    color: $color-main;
                  }
                  .iconfont{
                    @include res(margin-left,.05rem);
                  }
                }
              }
            }
            &.hover{
              .title{
                a::after{
                  transform: scaleX(100%);
                }
              }
              .sub-nav{
                opacity: 1;
                visibility: visible;
                transition-delay: .2s;
                @include res(top, 85%);
              }
            }
          }
        }
      }
      .right-fun{
        align-items: center;
        justify-content: flex-end;
        @include res(display,flex);
        .hamburger{
          @include res(margin-left,.5rem,(xs:.3rem));
          @include res(display,none,(md:block));
        }
      }
    }
  }
  .mob-header{
    position: fixed;
    top: 0;
    left: 100%;
    z-index: 998;
    height: 100vh;
    overflow-x: hidden;
    background: #fff;
    background-size: cover;
    transition: all $anime-duration;
    color: $color-main-dark;
    @include res(padding-top, 3rem,(xs:1.3rem));
    @include res(padding-bottom, 1.5rem,(xs:.5rem));
    @include res(padding-left, 1rem,(xs:.5rem));
    @include res(padding-right, 1rem,(xs:.5rem));
    @include res(width,60%,(sm:80%,xs:100%));
    &.show{
      transform: translateX(-100%);
    }
    ul{
      height: 100%;
      width: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      li{
        border-bottom: 1px solid rgba(0,0,0,.1);
        letter-spacing: .01rem;
        &.active{
          .title .iconfont{
            transform: rotate(90deg);
          }
        }
        .title{
          align-items: center;
          justify-content: space-between;
          text-transform: uppercase;
          @include res(line-height,1.4rem,(xs:1rem));
          @include res(padding-left,.3rem);
          @include res(display,flex);
          @include res(font-size,.4rem,(sm:.5rem,xs:.36rem));
          a{
            flex-grow: 1;
          }
          .iconfont{
            flex-shrink: 0;
            align-items: center;
            justify-content: center;
            transition: all $anime-duration;
            transform-origin: center;
            @include res(display,none);
            @include res(font-size,.4rem,(xs:.34rem));
            @include res(width,1.4rem,(xs:1rem));
            @include res(height,1.4rem,(xs:1rem));
          }
        }
        .sub-nav{
          display: none;
          @include res(padding-left, .3rem);
          @include res(padding-right, .3rem);
          @include res(padding-bottom,.3rem);
          &::after{
            content: '';
            display: block;
            clear: both;
          }
          >div{
            width: 50%;
            float: left;
          }
          a{
            display: block;
            @include res(margin-bottom,.2rem);
            .iconfont{
              @include res(margin-left,.05rem);
            }
          }
        }
        &:nth-child(2){
          .sub-nav a{
            width: 100%;
          }
        }
      }
    }
  }
  .menu-mask{
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.4);
    position: fixed;
    top:0;
    left: 0;
    z-index: 997;
    display: none;
  }
}