@import "../../assets/styles/utils";

.ny-banner{
  position: relative;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  @include res(padding-top,1rem,(sm:2.3rem,xs:1.5rem));
  @include res(padding-bottom,1.5rem,(sm:2.5rem,xs:1.7rem));
  @include res(min-height,40vw,(md:43vw,xs:70vw));
  &::after{
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.3);
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
  .bg-cover{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -2;
  }
  .banner-text{
    h1{
      text-align: center;
      font-weight: bold;
      overflow: hidden;
      letter-spacing: .1rem;
      @include res(margin-bottom,.3rem);
      @include res(font-size,.7rem,$font-70);
      span{
        display: block;
        transform: translateY(100%);
        transition: transform 3e3ms cubic-bezier(0.19, 1, 0.22, 1);
      }
      &.is-inview{
        span{
          transform: translateY(0);
        }
      }
    }
    .desc{
      margin: 0 auto;
      line-height: 1.9;
      @include res(max-width,10rem,(md:90%,sm:100%));
      p{
        @include res(text-indent,.3rem,(md:.4rem,sm:.55rem,xs:.4rem));
      }
    }
  }
}