@import "../../assets/styles/utils";

.pages,#page ul{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  @include res(margin-top,0,(md:.4rem,xs:.7rem));
  a,li{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #ffffff;
    transition: $anime-duration $anime-bezier;
    cursor: pointer;
    transition: all .4s;
    color: $color-main;
    @include res(margin-left,.04rem,(md:.06rem,xs:.04rem));
    @include res(margin-right,.04rem,(md:.06rem,xs:.04rem));
    @include res(font-size, .18rem,(md:.28rem,sm:.38rem,xs:.3rem));
    @include res(width,.46rem,(md:.8rem,sm:1rem,xs:.8rem));
    @include res(height,.46rem,(md:.8rem,sm:1rem,xs:.8rem));
    &:hover{
      background-color: $color-yellow;
    }
    &.active,&.xl-active{
      background-color: $color-yellow;
    }
    &.xl-prevPage,&.xl-nextPage{
      display: none;
    }
    &.disabled{
      opacity: .5;
      pointer-events: none;
    }
  }
  .page_jump_text{
    @include res(margin-left,.1rem);
    @include res(margin-right,.1rem);
  }
  .page_jump_input{
    @include res(width,.6rem,(md:.7rem,sm:.8rem,xs:.7rem));
    @include res(height,.3rem,(md:.4rem,sm:.5rem,xs:.4rem));
  }
  .page_jump_btn{
    background: none;
    border: none;
    cursor: pointer;
    transition: all;
    &:hover{
      color: $color-main;
    }
  }
}