@import "./utils";
@import "~jason-css/src/responsive/index";
@import "~swiper/swiper-bundle.min.css";
@import '~@fancyapps/fancybox/dist/jquery.fancybox.min.css';
@import './animate.css';
@import "../fonts/iconfont.css";

@font-face {
  font-family: 'Poppins-Bold';
  src: url('../fonts/Poppins-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

*{
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  outline:none;
}
html{
  font-size: 62.5%;
}
body {
  font-family: "PingFang SC","Microsoft YaHei";
  line-height: 1.2;
  position: relative;
  color: $color-main-dark;
  background-color: #f3fafe;
  @include res(font-size, 12px);
}
.comp-root{
  overflow-x: hidden;
  @include res(font-size,.18rem,(md:.28rem,sm:.38rem,xs:.3rem));
}


//主体内容部分宽度
.container {
  @include res(padding-left,1rem,(md:1rem,xs:.3rem));
  @include res(padding-right,1rem,(md:1rem,xs:.3rem));
}

.no_overflow{
  overflow: hidden;
}
.top_low {
  transform: translateY(5vw);
  opacity: 0;
  transition: transform 3e3ms cubic-bezier(0.19, 1, 0.22, 1),
              opacity 3e3ms cubic-bezier(0.19, 1, 0.22, 1);
  &.is-inview{
    transform: translateY(0);
    opacity: 1;
  }
}

.is-inview{
  .top_low {
    transform: translateY(0);
    opacity: 1;
  }
}

.pt-85{
  @include res(padding-top,.85rem);
}

.circle-bg{
	position: relative;
	z-index: 2;
  @include res(min-height,50vh,(sm:20vh));
  @include res(padding-bottom,5.2vw,(xs:.8rem));
  .top-bg{
    width: 100%;
    overflow: hidden;
    height: 0;
    position: absolute;
    top: 0;
    transform: translateY(-18%);
    z-index: -1;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    transition: height .5s;
    transition-delay: .1s;
    div{
      flex-shrink: 0;
      width: 136%;
      height: 100%;
      overflow: hidden;
      position: relative;
      background-color: #f3fafe;
      border-top-right-radius: 50%;
      border-top-left-radius: 50%;
    }
    &.is-inview{
      transition-delay: 0s;
      @include res(height,23.64vw,(xs:38vw));
    }
  }
  .footer-bg{
    width: 100%;
    overflow: hidden;
    height: 0;
    position: absolute;
    bottom: 0;
    transform: translateY(22%);
    z-index: -2;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    transition: height .8s;
    transition-delay: 0s;
    @include res(padding-bottom,.1rem);
    &::after{
      content: '';
      display: block;
      width: 136%;
      height: calc(100% - 1px);
      background-image: linear-gradient(to right,$yellow-gradient);
      position: absolute;
      bottom: 0;
      left: -18%;
      z-index: -1;
      border-bottom-right-radius: 50%;
      border-bottom-left-radius: 50%;
    }
    div{
      flex-shrink: 0;
      width: 136%;
      height: 100%;
      overflow: hidden;
      background-color: #f3fafe;
      border-bottom-right-radius: 50%;
      border-bottom-left-radius: 50%;
      border-bottom-style: solid;
      border-bottom-width: 10px;
      border-bottom-color: transparent;
      position: relative;
      z-index: 1;
    }
    &.is-inview{
      transition-delay: .3s;
      @include res(height,23.64vw,(xs:38vw));
    }
  }
}

.circle-bg-img{
  position: absolute;
  // top: 0%;
  // right: 0%;
  z-index: 1;
  transform: translate3d(100%,-100%,0);
  transition: all 3s;
  &.circle1{
    height: auto;
    @include res(width,4.2rem,(xs:2rem));
  }
  &.circle2{
    opacity: .3;
    height: auto;
    @include res(width,6.2rem,(xs:4rem));
  }
}

.common-more{
  display: inline-flex;
  align-items: center;
  border-radius:50px;
  background-image: linear-gradient(to right,$blue-gradient);
  position: relative;
  white-space: nowrap;
  @include res(font-size,.18rem,(md:.28rem,sm:.38rem,xs:.28rem));
  @include res(padding-left,.38rem,(md:.45rem));
  @include res(padding-right,.22rem,(md:.28rem,sm:.3rem,xs:.26rem));
  @include res(width,1.95rem,(md:2.8rem,sm:3.4rem,xs:2.8rem));
  @include res(height,.62rem,(md:.8rem,sm:1rem,xs:.8rem));
  .t1{
    color: #fff;
  }
  .btn-circle{
    position: absolute;
    top: -1px;
    left: -1px;
    bottom: -1px;
    right: -1px;
    z-index: 1;
    display: flex;
    align-items: center;
    background: $color-yellow;
    border-radius:50px;
    transition: clip-path .65s $anime-bezier,transform .65s $anime-bezier;
    @include res(clip-path,inset(0.07rem 0.07rem 0.06rem calc(100% - 0.58rem) round .5rem),(
      md: inset(.1rem .1rem .1rem calc(100% - .75rem) round .5rem),
      sm: inset(.13rem .13rem .13rem calc(100% - .93rem) round .5rem),
      xs: inset(.1rem .1rem .1rem calc(100% - .75rem) round .5rem),
    ));
    @include res(padding-left,.38rem,(md:.45rem));
    @include res(padding-right,.22rem,(md:.28rem,sm:.3rem,xs:.26rem));
    .t2{
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .icon{
        overflow: hidden;
        position: relative;
        &::before,&::after{
          content: '\e603';
          font-family: 'iconfont';
          display: inline-block;
          transition: transform .65s $anime-bezier;
          @include res(font-size,.18rem,(md:.28rem,sm:.38rem,xs:.3rem));
        }
        &::before{
          position: absolute;
          top: 0;
          right: 100%;
        }
      }
    }
  }
  &:hover{
    .btn-circle{
      clip-path: inset(0 round .5rem);
      .t2{
        .icon{
          &::before,&::after{
            transition-delay: .1s;
            transform: translateX(100%);
          }
        }
      }
    }
  }
}

.swiper-button-next,.swiper-button-prev{
  border-style: solid;
  border-color: $color-main;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity .3s;
  @include res(border-width,.01rem,(md:1px));
  @include res(width,.48rem,(md:.7rem,sm:1rem,xs:.8rem));
  @include res(height,.48rem,(md:.7rem,sm:1rem,xs:.8rem));
  &::after{
    content: '';
    display: block;
    position: absolute;
    top: -2px;
    left: -2px;
    bottom: -2px;
    right: -2px;
    background-color: $color-yellow;
    border-radius: 50%;
    transform-origin: center;
    transform: scale(0);
    opacity: 0;
    transition: transform .65s $anime-bezier,opacity .65s $anime-bezier;
  }
  .icon{
    overflow: hidden;
    position: relative;
    z-index: 1;
    &::before,&::after{
      font-family: 'iconfont';
      display: inline-block;
      transition: transform .65s $anime-bezier;
      @include res(font-size,.18rem,(md:.28rem,sm:.38rem,xs:.3rem));
    }
    &::after{
      color: $color-main;
    }
    &::before{
      position: absolute;
      color: $color-main-dark;
      @include res(top,4%,(xs:5%));
    }
  }
  &:hover{
    &::after{
      opacity: 1;
      transform: scale(1);
    }
  }
}
.swiper-button-next{
  .icon{
    &::before,&::after{
      content: '\e603';
    }
    &::before{
      right: 100%;
    }
  }
  &:hover{
    .icon{
      &::before,&::after{
        transform: translateX(100%);
      }
    }
  }
}
.swiper-button-prev{
  .icon{
    &::before,&::after{
      content: '\e602';
    }
    &::before{
      left: 100%;
    }
  }
  &:hover{
    .icon{
      &::before,&::after{
        transform: translateX(-100%);
      }
    }
  }
}

.common-title{
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include res(margin-bottom,.6rem,(xs:.4rem));
  h1{
    font-weight: bold;
    @include res(font-size,.5rem,$font-50);
  }
}

.common-btn{
  align-items: center;
  justify-content: center;
  background-color: #008cd6;
  border-radius: 50%;
  position: relative;
  @include res(display,inline-flex);
  @include res(width,.48rem,(md:.7rem,sm:1rem,xs:.8rem));
  @include res(height,.48rem,(md:.7rem,sm:1rem,xs:.8rem));
  &::after{
    content: '';
    display: block;
    position: absolute;
    top: -2px;
    left: -2px;
    bottom: -2px;
    right: -2px;
    background-color: $color-yellow;
    border-radius: 50%;
    transform-origin: center;
    transform: scale(0);
    opacity: 0;
    transition: transform .65s $anime-bezier,opacity .65s $anime-bezier;
  }
  .icon{
    overflow: hidden;
    position: relative;
    z-index: 1;
    &::before,&::after{
      content: '\e603';
      font-family: 'iconfont';
      display: inline-block;
      transition: transform .65s $anime-bezier;
      @include res(font-size,.18rem,(md:.28rem,sm:.38rem,xs:.3rem));
    }
    &::after{
      color: #fff;
    }
    &::before{
      position: absolute;
      color: $color-main-dark;
      right: 100%;
      @include res(top,4%,(xs:5%));
    }
  }
  // &:hover{
  //   &::after{
  //     opacity: 1;
  //     transform: scale(1);
  //   }
  //   .icon{
  //     &::before,&::after{
  //       transform: translateX(100%);
  //     }
  //   }
  // }
}

.pro-list{
  flex-wrap: wrap;
  @include res(width,calc(100% + .17rem),(xs:calc(100% + .1rem)));
  @include res(display,flex);
  li{
    flex-shrink: 0;
    border-radius: .1rem;
    background-color: $color-main-bg;
    text-align: center;
    box-sizing: border-box;
    transition: background-color .3s,box-shadow .3s,transform 3e3ms cubic-bezier(0.19, 1, 0.22, 1), opacity 3e3ms cubic-bezier(0.19, 1, 0.22, 1);
    @include res(width,calc(20% - .17rem),(sm:calc(33.33% - .17rem),xs:calc(33.33% - .1rem)));
    @include res(margin-right,.17rem,(xs:.1rem));
    @include res(margin-bottom,.17rem,(xs:.1rem));
    @include res(padding,.25rem,(xs:.15rem));
    .item-img{
      display: flex;
      align-items: center;
      justify-content: center;
      @include res(height,12.5vw,(sm:17.5vw,xs:21.5vw));
      @include res(margin-bottom,.1rem);
      img{
        display: block;
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
        transition: transform .3s;
      }
    }
    .item-text{
      @include res(font-size,.18rem,(md:.28rem,sm:.38rem,xs:.28rem));
    }
    &:hover{
      background-color: #fff;
      box-shadow: 0 .03rem .2rem rgba(223,242,251,1);
      .item-img img{
        transform: translateY(-.1rem);
      }
    }
  }
}

.intent-form{
  .intent-t{
    @include res(margin-bottom,.5rem,(md:.3rem,sm:.5rem,xs:.3rem));
    .title{
      font-weight: bold;
      @include res(font-size,.3rem,$font-30);
      @include res(margin-bottom,.1rem);
    }
    .desc{
      line-height: 1.6
    }
  }
  form{
    flex-wrap: wrap;
    justify-content: space-between;
    @include res(display,flex);
    .form-item{
      width: 100%;
      @include res(margin-bottom,.35rem,(md:.25rem,sm:.4rem,xs:.2rem));
      &.half{
        @include res(width,48%,(xs:100%));
      }
      label{
        display: block;
        @include res(margin-bottom,.1rem);
        span{
          color: #ff1111;
        }
      }
      input,textarea{
        display: block;
        width: 100%;
        border-radius: 5px;
        border-color: #6dbee8;
        outline: none;
        line-height: 1.6;
        border-style: solid;
        transition: box-shadow .3s;
        @include res(border-width,.01rem,(md:1px));
        &:focus{
          box-shadow: 0 0 .1rem rgba(#6dbee8,.5);
        }
        &:autofill{
          transition: background-color 5000s ease-in-out 0s,box-shadow .3s !important;
        }
      }
      input{
        @include res(padding,0 .2rem);
        @include res(height,.6rem,(md:.75rem,sm:1.1rem,xs:.9rem));
      }
      textarea{
        @include res(padding,.1rem .2rem);
      }
    }
    .form-btn{
      width: 100%;
      button{
        width: 100%;
        border-radius: 5px;
        border: none;
        background-color: #0557a7;
        cursor: pointer;
        transition: background-color .3s;
        color: #fff;
        @include res(height,.6rem,(md:.75rem,sm:1.1rem,xs:.9rem));
        &:hover{
          background-color: $color-main;
        }
      }
    }
  }
}

.select{
  position: relative;
  &::after{
    content: '\e602';
    font-family: 'iconfont';
    position: absolute;
    bottom: 0;
    color: #0558a8;
    pointer-events: none;
    transform: rotate(-90deg);
    @include res(line-height,.6rem,(md:.75rem,sm:1.1rem,xs:.9rem));
    @include res(right,.2rem);
    @include res(font-size,.22rem,(md:.32rem,sm:.42rem,xs:.3rem));
  }
  input{
    cursor: pointer;
  }
  .select-list{
    position: absolute;
    width: 100%;
    top: 105%;
    left: 0;
    z-index: 5;
    background-color: rgba(255,255,255,.6);
    border: solid $color-main-light;
    border-radius: .09rem;
    backdrop-filter: blur(10px);
    overflow-x: hidden;
    overflow-y: auto;
    transition: all .3s;
    opacity: 0;
    visibility: hidden;
    transform: translateY(5px);
    @include res(max-height,3rem,(md:4rem,sm:5rem,xs:4rem));
    @include res(border-width,.01rem,(md:1px));
    li{
      transition: all .3s;
      cursor: pointer;
      @include res(padding-left,.3rem);
      @include res(padding-right,.3rem);
      @include res(padding-top,.05rem,(md:.1rem));
      @include res(padding-bottom,.05rem,(md:.1rem));
      @include res(font-size,.18rem,(md:16px,sm:14px,xs:.28rem));
      &:hover{
        color: $color-main;
      }
      &.on{
        background-color: $color-main;
        color: #fff;
      }
    }
  }
  &.hover{
    .select-list{
      opacity: 1;
      visibility: visible;
      transform: translateY(0px);
    }
  }
}