@import "../../assets/styles/utils";

footer{
  .footer{
    background-color: #0171ac;
    align-items: flex-start;
    color: #fff;
    justify-content: space-between;
    @include res(display,flex,(sm:block));
    @include res(padding-top,1.75rem);
    @include res(padding-bottom,.3rem);
    .footer-info{
      flex-shrink: 0;
      @include res(width,25.3%,(sm:100%));
      @include res(text-align,null,(sm:center));
      @include res(margin-bottom,0,(sm:1rem,xs:.7rem));
      .logo{
        @include res(margin-bottom,.35rem);
        img{
          height: auto;
          @include res(width,2.65rem,(sm:3.5rem,xs:3rem));
        }
      }
      .phone{
        @include res(margin-bottom,.85rem,(sm:.4rem));
        @include res(font-size,.3rem,(md:.4rem,sm:.5rem,xs:.4rem));
      }
      .social{
        align-items: center;
        cursor: pointer;
        @include res(justify-content,flex-start,(sm:center));
        @include res(display,inline-flex);
        .img{
          position: relative;
          
          &:not(:last-child){
            @include res(margin-right,.15rem);
          }
          .iconfont{
            background-color: #fff;
            border-radius: 50%;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            color: #0171ac;
            @include res(width,.45rem,(md:.6rem,sm:1rem,xs:.8rem));
            @include res(height,.45rem,(md:.6rem,sm:1rem,xs:.8rem));
            @include res(font-size, .3rem,(md:.45rem,sm:.7rem,xs:.6rem));
          }
          .ewm{
            position: absolute;
            top: 100%;
            left: 50%;
            transform: translate3d(-50%,20px,0);
            background-color: #fff;
            opacity: 0;
            visibility: hidden;
            transition: opacity .3s,visibility .3s,transform .3s;
            @include res(padding,.05rem);
            &::after{
              content: '';
              display: block;
              border-style: solid;
              border-color: transparent transparent #fff transparent;
              position: absolute;
              bottom: 100%;
              left: 50%;
              transform: translateX(-50%);
              @include res(border-width,.06rem);
            }
            img{
              height: auto;
              display: block;
              @include res(width,1rem,(md:100px));
            }
          }
          &:hover{
            .ewm{
              opacity: 1;
              visibility: visible;
              transform: translate3d(-50%,10px,0);
            }
          }
        }
      }
    }
    .footer-nav,.footer-nav-mob{
      flex-shrink: 0;
      @include res(width,74.7%,(md:72%,sm:100%));
      ul{
        align-items: stretch;
        justify-content: space-between;
        flex-wrap: wrap;
        @include res(display,flex,(xs:block));
        li{
          flex-shrink: 0;
          @include res(width,30%,(xs:100%));
          @include res(margin-bottom,.6rem,(xs:0));
          @include res(border-bottom,null,(xs:1px solid rgba(255,255,255,.3)));
          .title{
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: solid rgba(255,255,255,.3);
            @include res(margin-bottom, .15rem,(xs:0));
            @include res(border-bottom-width,.01rem,(md:1px,xs:0));
            a{
              flex-grow: 1;
              @include res(font-size,.2rem,$font-20);
              @include res(margin-left,0,(xs:.1rem));
              @include res(padding-top,0,(xs:.2rem));
              @include res(padding-bottom,.2rem,(md:.25rem,sm:.3rem,xs:.2rem));
            }
            .iconfont{
              transition: all .3s;
              flex-shrink: 0;
              align-items: center;
              justify-content: center;
              @include res(display,inline-flex,(xs:none));
              @include res(width,null,(xs:1rem));
            }
          }
          .sub-nav{
            align-items: flex-start;
            justify-content: space-between;
            @include res(display,flex,(xs:none));
            @include res(padding-left,0,(xs:.3rem));
            @include res(padding-right,0,(xs:.3rem));
            @include res(padding-bottom,0,(xs:.2rem));
            &::after{
              clear: both;
              display: block;
              @include res(content,null,(xs:''));
            }
            >div{
              &:nth-child(1){
                @include res(float,left);
              }
              &:nth-child(2){
                @include res(float,right);
              }
            }
            a{
              opacity: .8;
              display: block;
              transition: all .3s;
              @include res(padding-top,0,(xs:.1rem));
              @include res(padding-bottom,0,(xs:.1rem));
              @include res(margin-top, .15rem,(md:.2rem,xs:0));
              &:hover{
                opacity: 1;
              }
              .iconfont{
                @include res(margin-left,.05rem);
              }
            }
          }
          &.active{
            .title{
              .iconfont{
                transform: rotate(90deg);
              }
            }
          }
        }
      }
    }
    .footer-nav{
      @include res(display,block,(xs:none));
    }
    .footer-nav-mob{
      @include res(display,none,(xs:block));
    }
  }

  .copyright{
    background-color: #1b7fb4;
    color: rgba(255,255,255,.6);
    align-items: center;
    justify-content: space-between;
    @include res(text-align,null,(xs:center));
    @include res(padding-top,.35rem,(xs:.2rem));
    @include res(padding-bottom,.35rem,(xs:.2rem));
    @include res(display,flex,(xs:block));
    @include res(font-size,.18rem,(md:.24rem,sm:.3rem,xs:.26rem));
    .left{
      @include res(margin-bottom,0,(xs:.1rem));
    }
    a{
      transition: all .3s;
      display: inline-block;
      &:hover{
        color: #fff;
      }
    }
    .site-map{
      @include res(display,null,(md:none));
    }
  }
}