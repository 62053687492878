﻿//背景用色
$color-main:#00b0ec; //主题色
$color-main-dark:#163f57;
$color-main-light:#1b7fb4; 
$color-main-bg:#dbf0fb;
$color-yellow: #f1de54;
$blue-gradient: #00a7e3,#0557a7;
$yellow-gradient: #e8d000,#ffe608 23%,#ffe60a 58%,#ce912c;

//border用色
$color-line:#f2f2f2;

//文字用色
$color-red:#ff0909; //错误提示文字颜色
$color-title:#000; //标题黑色
$color-text:#333; //正文颜色  80%黑
$color-desc:#666; //解释性文字颜色  60%黑

$font-16: (md:.26rem,sm:.36rem,xs:.26rem);
$font-20: (md:.3rem,sm:.4rem,xs:.32rem);
$font-22: (md:.32rem,sm:.42rem,xs:.34rem);
$font-30: (md:.4rem,sm:.5rem,xs:.36rem);
$font-32: (md:.42rem,sm:.52rem,xs:.38rem);
$font-50: (md:.6rem,sm:.76rem,xs:.5rem);
$font-60: (md:.7rem,sm:.86rem,xs:.54rem);
$font-70: (md:.8rem,sm:.9rem,xs:.64rem);

// 动画效果持续时间
$anime-duration: 0.4s;
//贝塞尔曲线
$anime-bezier:cubic-bezier(.785,.135,.15,.86);

//header高度
$header-height-base: 1.5rem;
$header-height-ratio: (md:1.4rem,sm:1.6rem,xs:1.1rem);

//内容部分左右留白
$container-gutter-base:8.125%;
$container-gutter-ratio: 6 / 8.125;

//阴影
$shadow-offset: 0px 3px;
$shadow-spread: 10px;
$shadow-color:#dbdada;

$jason-map-width: 400px !default;
$jason-map-duration: .5s !default;
$jason-map-theme: $color-main !default;

//汉堡包按钮参数设置
$hamburger-layer-width: 28px; //汉堡包按钮每一条线的宽度
$hamburger-layer-height: 3px; //汉堡包按钮每一条线的高度
$hamburger-layer-spacing: 5px; //汉堡包按钮每一条线的高度间隔
$hamburger-layer-color: #fff; //汉堡包按钮线的颜色
$hamburger-layer-border-radius: 0; //汉堡包按钮线的圆角效果

// 响应式断点，需要从小到大写
$layout-responsive-breakpoint: ( // 手机
  xs: (min-width: 0px, container-width: 100%),
  // 平板
  sm: (min-width: 768px, container-width: 100%),
  // 小屏
  md: (min-width: 992px, container-width: 100%),
  // 中屏
  mmd: (min-width: 1025px, container-width: 100%),
  // 大屏
  lg: (min-width: 1367px, container-width: 100%),

  llg: (min-width: 1601px, container-width: 100%)) !default;
