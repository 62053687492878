@font-face {
  font-family: "iconfont"; /* Project id 4540166 */
  src: url('iconfont.woff2?t=1717403995277') format('woff2'),
       url('iconfont.woff?t=1717403995277') format('woff'),
       url('iconfont.ttf?t=1717403995277') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-caozuo-wailian:before {
  content: "\e711";
}

.icon-jia1:before {
  content: "\e632";
}

.icon-jian1:before {
  content: "\e634";
}

.icon-tianmao:before {
  content: "\e757";
}

.icon-pay-jingdong:before {
  content: "\e8dd";
}

.icon-weixin:before {
  content: "\e604";
}

.icon-weibo:before {
  content: "\e63d";
}

.icon-bilibili1:before {
  content: "\e62a";
}

.icon-xiaohongshu:before {
  content: "\e605";
}

.icon-map-filling:before {
  content: "\e68c";
}

.icon-close:before {
  content: "\e620";
}

.icon-gengduoda:before {
  content: "\e633";
}

.icon-search:before {
  content: "\e600";
}

.icon-richanghuli:before {
  content: "\e613";
}

.icon-chalvchuhang:before {
  content: "\e614";
}

.icon-jiatinghuli:before {
  content: "\e615";
}

.icon-chuangketie:before {
  content: "\e616";
}

.icon-kouzhao:before {
  content: "\e617";
}

.icon-shizhijiance:before {
  content: "\e618";
}

.icon-linchuangyuanxian:before {
  content: "\e619";
}

.icon-shangchao:before {
  content: "\e61a";
}

.icon-xiaodu:before {
  content: "\e61b";
}

.icon-zhongduankongxiao:before {
  content: "\e61c";
}

.icon-yingyang:before {
  content: "\e61d";
}

.icon-yaodian:before {
  content: "\e61e";
}

.icon-yiyuan:before {
  content: "\e61f";
}

.icon-middle-left:before {
  content: "\e602";
}

.icon-middle-right:before {
  content: "\e603";
}

